(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');
    MyHippoCommonDirectives.directive('mhEscrowForm', mhEscrowForm);

    function mhEscrowForm () {
        return {
            scope: {
                forms: '=',
                data: '='
            },
            restrict: 'E',
            templateUrl: 'views/templates/escrowForm.html',
            controller: function ($scope) {
                $scope.$watch('data.zip', (newVal, oldVal) => {
                    const numberOnly = /^\d+$/.test(newVal);
                    if (newVal && (newVal.length > 5 || !numberOnly)) {
                        $scope.data.zip = oldVal;
                    }
                });
            }
        };
    }
})(window.angular, window._);
